import React from 'react'

import PDFReader from './PDFReader'

import styled, { keyframes } from "styled-components"
import { fadeInRight } from 'react-animations'

const fadeInRightAnimation = keyframes`${fadeInRight}`
const FadeInRightDiv = styled.div`animation: 1s ${ fadeInRightAnimation };`

const ReportPreview = (props) => {
  const { report, width, height } = props

  return (
    <FadeInRightDiv className="h-96 overflow-hidden">
      { report.includes("dummyimage") ? 
        <img className="rounded-md h-96" src={report} width="400px" height="400px" /> :
        <PDFReader file={report} width={width} height={height} />
      }
    </FadeInRightDiv>
  )
}

export default ReportPreview