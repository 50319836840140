import React from 'react'
import PDFReader from './PDFReader'
import { MdClose } from "react-icons/md"
import Spinner from './Spinner'

const PDFModal = (props) => {
  const{ width, height, onClose, report } = props
  
  return(
    <div id="modal" className="fixed z-10 inset-0 overflow-y-auto hidden -mt-6" aria-labelledby="modal-title" role="dialog" aria-modal="true">

      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div  className="absolute right-1 mt-1 text-red-700 cursor-pointer">
            <MdClose onClick={onClose} size={25}/>
          </div>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
                <PDFReader file={report} width={width} height={height}/>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button onClick={onClose} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-red-800 shadow-sm px-4 py-2 hover:bg-red-600 text-base font-medium hover:text-white text-red-600 bg-white transform duration-300 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PDFModal
