import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { authProtectedRoutes } from "./routes"
import Routes from "./routes/Routes"
import { pdfjs } from 'react-pdf'
import Login from "./pages/Login"

import "./App.css"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const App = () => {
  return (
    <Router>
      <div className="m-4 overflow-hidden mt-12 pt-4">
        <Switch>
          <Route exact path='/' component={Login} />

          { authProtectedRoutes.map((route, idx) => (
              <Routes
                path={route.path}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
          ))}

        </Switch>
      </div>
    </Router>
  )
}

export default App
