const { REACT_APP_BASE_URL } = process.env

//API communicator for logging users in
export const LabLoginApi = async (user) => {
  const response = await fetch(REACT_APP_BASE_URL + "/login", {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user),
  })
  return await response.json()
}

//API communicator for logging users out
export const LabLogoutApi = async () => {
  const response = await fetch(REACT_APP_BASE_URL + "/logout", {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  })
  return await response.json()
}

export const NewTransactionApi = async (transactionData) => {
  const response = await fetch(REACT_APP_BASE_URL + "/transactions/new", {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
    },
    body: transactionData,
  })
  return await response.json()
}

export const MineApi = async () => {
  const response = await fetch(REACT_APP_BASE_URL + "/mine", {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  })
  return await response.json()
}

export const ReportSearchApi = async (reportData) => {
  const response = await fetch(REACT_APP_BASE_URL + "/report/base64_report", {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    },
    body: reportData,
  })
  return await response.json()
}