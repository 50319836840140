import React from 'react'
import { MdClose } from "react-icons/md"
import { GiWarPick } from "react-icons/gi"

import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'
import Spinner from './Spinner'
import Lottie from 'react-lottie'
import miningAnimation from '../lotties/miningLottie.json'

const fadeInAnimation = keyframes`${fadeIn}`

const FadeInDiv = styled.div`animation: 0.8s ${ fadeInAnimation };`

const MiningModal = (props) => {

  const { mining, onClose } = props

  const miningDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: miningAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  return (
    <FadeInDiv id="mining-modal" className="fixed z-10 inset-0 overflow-y-auto hidden rounded-xl" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 rounded-3xl">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
        <div className="inline-block align-bottom bg-gray-500 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-gray-500 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
            <Lottie
              style={{ borderRadius: "50px", cursor: "default" }} 
              options={miningDefaultOptions}
              height={443}
              width={630}
            />
            </div>
          </div>
        </div>
      </div>
    </FadeInDiv>
  )
}

export default MiningModal
