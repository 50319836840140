import React, { useState } from 'react'
import { css } from "@emotion/react"
import ClockLoader from "react-spinners/ClockLoader"
import PropagateLoader from "react-spinners/PropagateLoader"

const override = css`
  display: inline-block;
  margin-top: 10
`;

const initialState = {
  color: "#1D4ED8"
}

const Spinner = (props) => {

  const { mining, loading, size } = props

  const [color, setColor] = useState(initialState.color)

  return mining ? 
         <PropagateLoader color={color} loading={loading} css={override} size={size} /> : 
         <ClockLoader color={color} loading={loading} css={override} size={size} />
}

export default Spinner
