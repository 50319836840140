import React, { useEffect, useRef, useState } from 'react'
import { NewTransactionApi, MineApi, ReportSearchApi } from '../api/ApiHandler'


import ReportPreview from "../components/ReportPreview"
import ReportList from '../components/ReportList'

import PDFModal from '../components/PDFModal'
import MiningModal from '../components/MiningModal'

import { GiWarPick } from "react-icons/gi"
import { VscFilePdf } from "react-icons/vsc"
import { MdDone } from "react-icons/md"

import Spinner from '../components/Spinner'

import "../App.css"

import styled, { keyframes } from "styled-components"
import { fadeInUp } from 'react-animations'
import Logout from '../components/Logout'


const fadeInUpAnimation = keyframes`${fadeInUp}`

const FadeInUpDiv = styled.div`animation: 1s ${ fadeInUpAnimation };`

const initialState = {
  reports: [],
  mined: false,
  mining: false,
  fileError: false,
  reportToView: "",
  processing: false,
  readyToMine: false,
  pdfModalWidth: 465,
  pdfModalHeight: 465,
  pdfPreviewWidth: 400,
  pdfPreviewHeight: 200,
  report: "https://dummyimage.com/400x400/d9d9d9/000000&text=Select+Report+to+preview",
}

const UploadReport = () => {
  const firstUpdate = useRef(true)
  const [mined, setMined] = useState(initialState.mined)
  const [reportToView, setReportToView] = useState(initialState.reportToView)
  const [mining, setMining] = useState(initialState.mining)
  const [report, setReport] = useState(initialState.report)
  const [readyToMine, setReadyToMine] = useState(initialState.readyToMine)
  const [reports, setReports] = useState(initialState.reports)
  const [fileError, setFileError] = useState(initialState.fileError)
  const [processing, setProcessing] = useState(initialState.processing)
  const [reportPreview, setReportPreview] = useState(initialState.reportPreview)

  useEffect(() => {
    console.log(localStorage.length)
    for(var i = 0; i < localStorage.length - 1; i++) {
      var report = localStorage.getItem(`report${i}`)
      setReports(prevReports => [...prevReports, report])
    }
  }, [])

  useEffect(() => {
    if(firstUpdate.current) {
      firstUpdate.current = false
      return
    } else {
      console.log(report)

      const reportFormData = new FormData()

      reportFormData.append("report", report)

      NewTransactionApi(reportFormData)
      .then(res => {
        reports.push(res.report_id)
        let numOfReports = localStorage.length - 1
        console.log(numOfReports)
        localStorage.setItem(`report${numOfReports}`, res.report_id)
        setReadyToMine(true)
        setProcessing(false)
      })
      .catch(err => {
        console.log(err)
        setReadyToMine(false)
        setProcessing(false)
        alert(err)
      })
    }
  }, [report])

  const fileValidator = (file) => {
    let fileName = file.name

    if(!fileName.includes("pdf")) {
      setFileError(true)
      return false
    }
    else {
      setFileError(false)
      return true
    }
  }

  const handleReportChange = (e) => {
    encodeReportAsBase64(e)
  }

  const encodeReportAsBase64 = (e) => {
    const file = e.target.files[0]

    if(fileValidator(file)) {
      setProcessing(true)

      var reader = new FileReader()
      reader.readAsDataURL(file)
  
      reader.onloadend = function() {
        setReport(reader.result)
      }
    }
  }

  const handleMineClick = () => {
    setMining(true)
    setReadyToMine(false)

    handleShowMiningModal()

    MineApi()
    .then(res => {
      console.log(res)
      setMining(false)
      setMined(true)
      handleCloseMiningModal()
    })
    .catch(err => {
      setMining(false)
      setMined(false)
      console.log(err)
      alert("Something went wrong, cannot mine")
    })
  }

  const handleShowModal = (e) => {
    const reportIdFormData = new FormData()

    reportIdFormData.append("report_id", e.target.innerHTML)

    ReportSearchApi(reportIdFormData)
    .then(res => {
      console.log(res)
      setReportToView(res.report)

      document.getElementById("modal").classList.remove("hidden")
      document.getElementById("modal").classList.add("block")
    })
    .catch(err => {
      console.log(err)
    })
  }

  const handleCloseModal = () => {
    document.getElementById("modal").classList.remove("block")
    document.getElementById("modal").classList.add("hidden")
  }

  const handleShowMiningModal = (e) => {
    document.getElementById("mining-modal").classList.remove("hidden")
    document.getElementById("mining-modal").classList.add("block")
  }

  const handleCloseMiningModal = () => {
    document.getElementById("mining-modal").classList.remove("block")
    document.getElementById("mining-modal").classList.add("hidden")
  }

  return (
    <div className="text-center grid grid-rows-2 justify-items-center">
      <Logout />
      { fileError ? 
        <p className="font-bold text-red-600">* Wrong File Type Detected</p> :
        <p className="text-white">*</p>
      }
      <div className="flex mt-4 space-x-2 justify-center h-96 overflow-hidden">
        <ReportList reports={reports} handleReportClick={handleShowModal} />
        <ReportPreview report={readyToMine ? report : initialState.report} width={initialState.pdfPreviewWidth} height={initialState.pdfPreviewHeight} />
      </div>
      <FadeInUpDiv className="space-x-3 mt-5 mb-5 ">
        { readyToMine ? 
          <label id="mine" className="bg-white my-2 text-yellow-500 font-semibold p-2 rounded-lg border-yellow-800 border hover:ease-in-out duration-300 hover:text-white hover:bg-yellow-500" onClick={handleMineClick}><GiWarPick style={{ display: "inline", marginBottom: "5" }} size={24} />{" "}<span>Mine your Block</span></label> :
          mined ? 
          <label id="mine" className="bg-white my-2 text-green-500 font-semibold p-2 rounded-lg border-green-700 border hover:ease-in-out duration-300 hover:text-white hover:bg-green-500"><MdDone style={{ display: "inline", marginBottom: "5" }} size={24} />{" "}<span>Block Mined</span></label> : <></>
          // <label id="mine" className="bg-white my-2 text-yellow-500 font-semibold p-2 rounded-lg border-yellow-800 border hover:ease-in-out duration-300 hover:text-white hover:bg-yellow-500"><GiWarPick style={{ display: "inline", marginBottom: "5" }} size={24} />{" "}<span>Mine your Block</span></label>
        }
        <input id="imageInput" className="hidden" type="file" accept="application/pdf" onChange={handleReportChange}/>
        { processing ? 
          <label className="relative bg-white outline-none" htmlFor="imageInput"><Spinner loading={processing} size={40} /></label> :
          <label className="relative bg-white cursor-pointer text-blue-700 font-semibold p-2 rounded-lg border-blue-800 border hover:ease-in-out duration-300 hover:text-white hover:bg-blue-600" htmlFor="imageInput"><VscFilePdf style={{ display: "inline", marginBottom: "5" }} size={20}/> <span>Select Report</span></label>
        }
      </FadeInUpDiv>
      <PDFModal report={reportToView} width={initialState.pdfModalWidth} height={initialState.pdfModalHeight} onClose={handleCloseModal}/>
      <MiningModal mining={mining} onClose={handleCloseMiningModal} />
    </div>
  )
}

export default UploadReport
