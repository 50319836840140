import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const Routes = ({ component: Component, isAuthProtected, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if(!isAuthProtected || !localStorage.getItem("token")) 
        return (
          <Redirect
            to={{ pathname: "/", state: { from: props.location } }}
          />
        )
      else
        return (
          <Component {...props} />
        )
    }}
  />
)

export default Routes
