import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'

import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation: 1s ${ fadeInAnimation };`


const PDFReader = (props) => {
  const { file, width, height } = props

  const [numPages, setNumPages] = useState(null)

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages)
  }

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  };

  return (
    <FadeInDiv>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        { Array.from(
            new Array(numPages),
            (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1 }
                width={width}
                height={height}
              />
            )
          )
        }
      </Document>
    </FadeInDiv>
  )
}

export default PDFReader
