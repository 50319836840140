import React from 'react'
import { FiLogOut } from "react-icons/fi"
import { LabLogoutApi } from '../api/ApiHandler'

const Logout = () => {

  const handleLogout = () => {
    LabLogoutApi()
    .then(res => {
      if(res.message === 'Successfully logged out') {
        localStorage.clear()
        window.location.reload()
      }
    })
    .catch(err => {
      console.log(err)
      alert("Something went wrong. " + err)
    })
  }

  return (
    <button onClick={handleLogout} className="mb-3 w-1/6 rounded-lg p-2 border border-red-700 focus:outline-none outline-none bg-white text-red-500 hover:text-white hover:bg-red-500 transform duration-500">Logout <FiLogOut className="inline ml-2 animate-bounce mb-0.5" /></button>
  )
}

export default Logout
