import React, { useEffect, useState } from 'react'

import { FiLogIn } from "react-icons/fi"
import { AiOutlineEye } from "react-icons/ai"
import { AiOutlineEyeInvisible } from "react-icons/ai"

import { LabLoginApi } from '../api/ApiHandler'

import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'
import { useHistory } from 'react-router'

const fadeInAnimation = keyframes`${fadeIn}`

const FadeInDiv = styled.div`animation: 1s ${ fadeInAnimation };`

const initialState = {
  user: {
    email: "",
    password: ""
  },
  isVisible: false
}

const Login = () => {
  const history = useHistory()
  const [user, setUser] = useState(initialState.user)
  const [isVisibile, setIsVisibile] = useState(initialState.isVisible)

  useEffect(() => {
    if(localStorage.getItem("token"))
      history.push("/upload-report")
  }, [])

  const handleOnChange = (e) => {
    const { name, value } = e.target

    setUser(prevUser => ({ ...prevUser, [name]: value }))
  }

  const handlePasswordVisibility = () => {
    setIsVisibile(!isVisibile)
  }

  const handleLoginClick = () => {

    LabLoginApi(user)
    .then(res => {
      if(res.message !== "wrong username or password") {
        localStorage.setItem("token", res.token)
        history.push("/upload-report")
      } else {
        alert("Wrong Username or Password. Try Again...")
      }

    })
    .catch(err => {
      alert("Error: " + err)
    })
  }

  return (
    <FadeInDiv className="grid grid-cols-1 justify-items-center mb-5">
      <div className="grid grid-rows-4 gap-4 bg-gray-100 p-7 rounded-xl shadow-lg">
        <label className="text-2xl text-center font-semibold text-gray-500">PAKISTAN VACCINATED PASSPORT</label>
        <input name="email" onChange={handleOnChange} type="text" className="p-2 outline-none rounded-lg border-2 border-gray-800 border-opacity-20 focus:border-opacity-80 hover:border-opacity-50 transform duration-500" placeholder="Enter Email..." />
        <div className=" p-2 outline-none rounded-lg border-2 border-gray-800 border-opacity-20 bg-white focus:border-opacity-80 hover:border-opacity-50 transform duration-500">
          <input name="password" onChange={handleOnChange} type={isVisibile ? "text" : "password"} maxLength="6" className="outline-none" placeholder="Enter Password..." />
          { isVisibile ?
            <AiOutlineEyeInvisible onClick={handlePasswordVisibility} size="18" className="inline mt-1 mr-2 float-right" /> :
            <AiOutlineEye onClick={handlePasswordVisibility} size="18" className="inline mt-1 mr-2 float-right" />
          }
        </div>
        <button onClick={handleLoginClick} className="px-14 rounded-lg text-gray-700 focus:outline-none focus:bg-blue-800 border border-blue-800 hover:bg-blue-500 hover:text-white focus:text-white transform duration-500"><FiLogIn className="inline ml-1 animate-bounce" /> Login</button>
      </div>
    </FadeInDiv>
  )
}

export default Login
