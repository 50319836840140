import React, { useEffect } from 'react'
import { AiOutlineEye } from "react-icons/ai";

const ReportListItem = (props) => {
  const { report, handleReportClick } = props

  return (
    <div className="rounded-2xl p-1 mx-1 text-white hover:bg-gray-200 hover:text-gray-700 transform duration-300 ease-in-out mb-1" onClick={handleReportClick}>
      <div className="inline-flex">
        <p className="font-medium cursor-pointer">{report}</p>
        {/* <div className="mt-1 absolute right-2">
          <AiOutlineEye />
        </div> */}
      </div>
    </div>
  )
}

export default ReportListItem
